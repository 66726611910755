import { getApiDataStats } from '../../utils/apiData';

const getLinks = document.querySelectorAll('._download');

getLinks.forEach((element) => {
  element.addEventListener('click', sendDataStats);
});

async function sendDataStats(event) {
  if (event.target.classList.contains('_download-docs')) {
    await getApiDataStats('duraku666', 'Download license');
  } else {
    await getApiDataStats('duraku666', 'Download Demo');
  }
}
