// Плавная навигация по клику на меню
const headerMenuLinks = document.querySelectorAll(
  '.menu-header__link[data-goto]',
);
const footerMenuLinks = document.querySelectorAll('.footer__link[data-goto]');
const headerMenuButtonsLinks = document.querySelectorAll(
  '.body-main__button[data-goto]',
);
smoothScroll(headerMenuLinks);
smoothScroll(footerMenuLinks);
smoothScroll(headerMenuButtonsLinks);

function smoothScroll(dataLinks) {
  if (dataLinks.length > 0) {
    dataLinks.forEach((element) => {
      element.addEventListener('click', onMenuLinkClck);
    });

    function onMenuLinkClck(e) {
      const menuLinkTarget = e.target;
      if (
        menuLinkTarget.dataset.goto
        && document.querySelector(menuLinkTarget.dataset.goto)
      ) {
        const gotoBlock = document.querySelector(menuLinkTarget.dataset.goto);
        const gotoBlockValue = gotoBlock.getBoundingClientRect().top
          + 136
          + scrollY
          - document.querySelector('header').offsetHeight;

        if (iconMenu.classList.contains('_active')) {
          document.body.classList.toggle('_lock');
          iconMenu.classList.remove('_active');
          menuHeader.classList.remove('_open');
          headerBody.classList.remove('_open');
        }

        window.scrollTo({
          top: gotoBlockValue,
          behavior: 'smooth',
        });
        e.preventDefault();
      }
    }
  }
}

// Логика бургер меню
const iconMenu = document.querySelector('.header__icon');
const menuHeader = document.querySelector('.menu-header');
const headerBody = document.querySelector('.header__body');
const menuHeaderItems = document.querySelector('.menu-header__items-lnt');

if (iconMenu) {
  iconMenu.addEventListener('click', () => {
    document.body.classList.toggle('_lock');
    iconMenu.classList.toggle('_active');
    menuHeader.classList.toggle('_open');
    headerBody.classList.toggle('_open');
    menuHeaderItems.classList.toggle('_open');
  });
}
