/* eslint-disable linebreak-style */
/* eslint-disable radix */
/* eslint-disable no-tabs */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable linebreak-style */
import { getApiDataStats } from '../../utils/apiData';

const calculateButton = document.querySelector('.form-calculate__button');
if (calculateButton) {
  calculateButton.addEventListener('click', calculateData);
}

async function calculateData(event) {
  event.preventDefault();

  const phonesToCalculate = parseInt(
    document.querySelector('.input-item').value,
  );
  const areaToCalculate = parseInt(document.querySelector('.input-area').value);
  const rangeOfAccuracy = parseInt(
    document.querySelector('.input-range').value,
  );

  const marks = marksCalculate(areaToCalculate, rangeOfAccuracy);

  if (!inputValidate()) {
    printTotalAmount(
      calculateTotalAmount(
        phonesToCalculate,
        marksPrice(marks),
        priceMarksInstall(marks),
      ),
    );

    const message = `
		Количество устройв Астер = ${phonesToCalculate} шт.,
		Площадь = ${areaToCalculate} кв.м., 
		Точность = ${
  rangeOfAccuracy === 0
    ? 'Низкая'
    : rangeOfAccuracy === 1
      ? 'Средняя'
      : 'Высокая'
}
	`;

    /* Отправка статистики на сервер */
    await getApiDataStats('duraku666', message);
  }
}

/* Количество меток */
function marksCalculate(areaToCalculate, rangeOfAccuracy) {
  let result;

  switch (rangeOfAccuracy) {
    case 0:
      result = areaToCalculate / 50;
      break;
    case 1:
      result = areaToCalculate / 32;
      break;
    case 2:
      result = areaToCalculate / 16;
      break;
    default:
      result = 0;
  }
  return Math.ceil(result);
}

/* Стоимомть всех меток M */
function marksPrice(marks) {
  return marks * 2500;
}

/* Стоимость работ по установке всех меток R */
function priceMarksInstall(marks) {
  return marks * 1500;
}

function calculateTotalAmount(
  phonesToCalculate,
  marksPrice,
  priceMarksInstall,
) {
  const totalPrice = phonesToCalculate * (20000 + 72000) + marksPrice + priceMarksInstall;
  return totalPrice;
}

function printTotalAmount(totalAmount) {
  const printPrice = document.querySelector('.calculate-price__total-amount');
  printPrice.innerHTML = new Intl.NumberFormat('ru-RU').format(totalAmount) || 0;
  printPrice.setAttribute('display', 'flex');
  printPrice.classList.add('visible');
}

function inputValidate() {
  let error = 0;
  const inputs = document.querySelectorAll('._req');
  inputs.forEach((input) => {
    removeError(input);

    if (input.classList.contains('_devices')) {
      if (
        Math.sign(input.value) === -1
        || input.value > 500
        || input.value === ''
      ) {
        addError(input);
        error++;
      }
    }

    if (input.classList.contains('_area')) {
      if (
        Math.sign(input.value) === -1
        || input.value > 100000
        || input.value === ''
      ) {
        addError(input);
        error++;
      }
    }
  });
  return error;
}

function addError(input) {
  input.classList.add('_error');
}

function removeError(input) {
  input.classList.remove('_error');
}
