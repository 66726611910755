const API_URL_SEND_STATISTICS = 'https://dib-support.lanit.ru/api/landing/sendaction?';
const API_URL_SEND_MAIL_CONTACTS = 'https://dib-support.lanit.ru/api/landing/send_mail?';

export async function getApiDataStats(xkey, message) {
  const params = `xkey=${encodeURIComponent(xkey)}&message=${encodeURIComponent(message)}`;
  const url = API_URL_SEND_STATISTICS + params;

  try {
    const response = await fetch(url);
    return response.json();
  } catch (e) {
    throw new Error('Error fetching data');
  }
}

export async function getApiDataContacts(xkey, name, contact, content) {
  /*
    Если нужно отправить запрос через GET
    const queryParam = `xkey=${xkey}&name=${name}&Contact=${contact}&Content=2${content}`;
    const url = API_URL + queryParam;
  */

  try {
    const response = await fetch(API_URL_SEND_MAIL_CONTACTS, {
      method: 'POST',
      body: JSON.stringify({
        xkey,
        name,
        contact,
        content,
      }),
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.json();
  } catch (e) {
    throw new Error('Error fetching data');
  }
}
