/* eslint-disable import/extensions */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */

import { getApiDataContacts } from '../../utils/apiData';

const button = document.getElementById('advantages-button-call');
const modal = document.getElementById('myModal-advantages');
const span = modal.getElementsByClassName('close')[0];

button.addEventListener('click', (event) => {
  event.preventDefault();
  modal.style.display = 'block';
});

span.addEventListener('click', () => {
  modal.style.display = 'none';
  contactsMainForm.reset();
});

modal.onclick = function (event) {
  if (!event.target.closest('.modal-content-main')) {
    modal.style.display = 'none';
    contactsMainForm.reset();
  }
};

const contactsMainButton = document.querySelector('.button__advantages-modal');
const contactsMainForm = document.querySelector('.form__advantages-modal');
const inputCheckBoxMain = document.querySelector('.input-4-advantages');

if (contactsMainButton) {
  // eslint-disable-next-line no-use-before-define
  contactsMainButton.addEventListener('click', sendData);
}

async function sendData(event) {
  event.preventDefault();

  const inputName = document.querySelector('.input-1-advantages').value;
  const inputContact = document.querySelector('.input-2-advantages').value;
  const inputMessage = document.querySelector('.input-3-advantages').value;
  const isCheked = document.querySelector('.input-4-advantages').checked;
  const toast = document.querySelector('.toast-advantages');

  // eslint-disable-next-line no-use-before-define
  if (!inputValidate() && isCheked) {
    contactsMainForm.classList.add('_sending');
    const response = await getApiDataContacts(
      'duraku666',
      inputName,
      inputContact,
      inputMessage,
    );
    if (response) {
      contactsMainForm.classList.remove('_sending');
      toast.classList.add('_done');
    }
    contactsMainForm.reset();
    removeErrorCheckBox();

    setTimeout(() => {
      modal.style.display = 'none';
      contactsMainForm.classList.remove('_sending');
      toast.classList.remove('_done');
    }, 1000);
  }
}

function isEmpty(string) {
  return string.length === 0 || !string.trim();
}

function addError(input) {
  input.classList.add('_error-contacts');
}

function removeError(input) {
  if (input) {
    input.classList.remove('_error-contacts');
  }
}

function addErrorCheckBox() {
  inputCheckBoxMain.classList.add('checked__error');
}

function removeErrorCheckBox() {
  inputCheckBoxMain.classList.remove('checked__error');
}

function inputValidate() {
  let error = 0;
  const inputs = document.querySelectorAll('._req-msg-advantages');

  inputs.forEach((input) => {
    const inputParent = input.closest('.form-advantages');
    removeError(inputParent);
    // removeErrorCheckBox();

    if (input.classList.contains('input-1-advantages')) {
      if (isEmpty(input.value)) {
        addError(inputParent);
        // eslint-disable-next-line no-plusplus
        error++;
      }
    }

    if (input.classList.contains('input-2-advantages')) {
      if (!validatePhoneNumber(input.value) && !validateEmail(input.value)) {
        addError(inputParent);
        // eslint-disable-next-line no-plusplus
        error++;
      }
    }

    if (input.classList.contains('input-3-advantages')) {
      if (isEmpty(input.value)) {
        addError(inputParent);
        error++;
      }
    }

    if (input.classList.contains('input-4-advantages')) {
      if (!input.checked) {
        addErrorCheckBox();
        error++;
      }
    }
  });

  return error;
}

function validatePhoneNumber(phone) {
  const phoneRegValidate = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,5}$/im;
  return phoneRegValidate.test(phone);
}

function validateEmail(email) {
  const emailRegValidate = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/im;
  return emailRegValidate.test(email);
}
