/* eslint-disable import/extensions */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-use-before-define */
/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
import { getApiDataContacts } from '../../utils/apiData.js';

const contactsMainButton = document.querySelector('.__button-contact');
const contactsMainForm = document.querySelector('.form-contacts');
const inputCheckBox = document.querySelector('.input-4-contacts');

if (contactsMainButton) {
  contactsMainButton.addEventListener('click', sendData);
}

async function sendData(event) {
  event.preventDefault();

  const inputName = document.querySelector('.input-1-contacts').value;
  const inputContact = document.querySelector('.input-2-contacts').value;
  const kolvo = document.querySelector('.input-3-contacts').value;
  const org = document.querySelector('.input-5-contacts').value;
  const email = document.querySelector('.input-6-contacts').value;
  const inputMessage = `Количество сотрудников: ${kolvo} Организация: ${org} Email: ${email}`;
  const isCheked = document.querySelector('.input-4-contacts').checked;
  const toast = document.querySelector('.toast-contacts');

  if (!inputValidate() && isCheked) {
    contactsMainForm.classList.add('_sending');

    const response = await getApiDataContacts(
      'duraku666',
      inputName,
      inputContact,
      inputMessage,
    );

    if (response) {
      contactsMainForm.classList.remove('_sending');
      toast.classList.add('_done');
    }
    contactsMainForm.reset();

    setTimeout(() => {
      contactsMainForm.classList.remove('_sending');
      toast.classList.remove('_done');
    }, 1000);
  }
}

function isEmpty(string) {
  return string.length === 0 || !string.trim();
}

function addError(input) {
  input.classList.add('_error-contacts');
}

function addErrorCheckBox() {
  inputCheckBox.classList.add('checked__error');
}

function removeError(input) {
  if (input) {
    input.classList.remove('_error-contacts');
  }
}

function removeErrorCheckBox() {
  inputCheckBox.classList.remove('checked__error');
}

function inputValidate() {
  let error = 0;
  const inputs = document.querySelectorAll('._req-msg-contacts');

  inputs.forEach((input) => {
    const inputParent = input.closest('.form-part');
    removeError(inputParent);
    removeErrorCheckBox();
    if (input.classList.contains('input-1-contacts')) {
      if (isEmpty(input.value)) {
        addError(inputParent);
        error++;
      }
    }

    if (input.classList.contains('input-2-contacts')) {
      if (isEmpty(input.value) || !validatePhoneNumber(input.value)) {
        addError(inputParent);
        error++;
      }
    }

    if (input.classList.contains('input-3-contacts')) {
      if (isEmpty(input.value) || !validateCount(input.value)) {
        addError(inputParent);
        error++;
      }
    }

    if (input.classList.contains('input-5-contacts')) {
      if (isEmpty(input.value)) {
        addError(inputParent);
        error++;
      }
    }

    if (input.classList.contains('input-6-contacts')) {
      if (isEmpty(input.value) || !validateEmail(input.value)) {
        addError(inputParent);
        error++;
      }
    }

    if (input.classList.contains('input-4-contacts')) {
      if (!input.checked) {
        addErrorCheckBox();
        error++;
      }
    }
  });
  return error;
}

function validatePhoneNumber(phone) {
  const phoneRegValidate = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,5}$/im;
  return phoneRegValidate.test(phone);
}

function validateEmail(email) {
  const emailRegValidate = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/im;
  return emailRegValidate.test(email);
}

function validateCount(count) {
  const numberRegValidate = /^\d+$/;
  return numberRegValidate.test(count);
}
